export type SimpleObject = { [key: string]: any };
export type SimpleStringObject = { [key: string]: string };
export type CustomObject<T> = { [key: string]: T };

export enum ImageFormats {
  Png = 'png',
  Jpg = 'jpeg'
}

export type RuleConditionSign = '<' | '>' | '<=' | '>=';
export type RuleOperatorSign = '+' | '-' | '*';
export type RuleUnity = 'cajas' | '% de lote';

export const SignalValues = {
  cartData: 'cartData',
  cartDataBroadcast: 'cartDataBroadcast',
  initialData: 'initialData',
  initialDataBroadcast: 'initialDataBroadcast',
  connectedUsers: 'connectedUsers',
  joinAuctionChannel: 'joinAuctionChannel',
  increaseAuction: 'increaseAuction',
  auctionBroadcast: 'auctionBroadcast',
  connectedUsersBroadcast: 'connectedUsersBroadcast',
  countdownBroadcast: 'countdownBroadcast',
  disconnect: 'disconnect'
};

export enum StockOriginValues {
  Bolsa = 'BOLSA',
  Estandar = 'ESTANDAR'
}
export type StockOriginValuesType = (typeof StockOriginValues)[keyof typeof StockOriginValues];

export const discountRulesDictionary = {
  conditionSign: {
    '<': 'inferior',
    '<=': 'igual o menos',
    '>': 'sobre',
    '>=': 'desde'
  },
  unity: {
    cajas: 'cajas',
    '% de lote': '% del lote'
  },
  operatorSign: {
    '+': 'se agrega',
    '-': 'se descuenta',
    '*': 'obten un'
  }
};

export enum UserTypeValues {
  Admin = 'ADMIN',
  Operador = 'OPERADOR',
  Cliente = 'CLIENTE'
}
export type UserTypeValuesType = (typeof UserTypeValues)[keyof typeof UserTypeValues];


export enum StateValues {
  Activo = 'Activo',
  Cancelado = 'Cancelado',
  Vendido = 'Vendido',
  Reservado = 'Reservado',
  PendientePago = 'Pendiente de Pago',
  Finalizado = 'Finalizado'
}

export enum DeliveryTypeValues {
  Despacho = 'Despacho',
  Retiro = 'Retiro'
}

export interface UserType extends BasicEntity {
  userTypeId: string;
  userTypeName: string;
}

export interface Country extends BasicEntity {
  countryId: string;
  countryName: string;
}

export interface GeneralSaleRule extends BasicEntity {
  generalSaleRuleId: string;
  ruleName: string;
  description: string;
  ruleValue: string;
}

export interface DiscountRule extends BasicEntity {
  discountRuleId: string;
  conditionSign: RuleConditionSign;
  quantity: number;
  unity: RuleUnity;
  operatorSign: RuleOperatorSign;
  valueToOperate: number;
}

export interface Brand extends BasicEntity {
  brandId: string;
  brandName: string;
}

export interface StockMovementAudit extends BasicEntity {
  stockEntityAuditId: string;
  userId: string;
  userUsername?: string;
  stockId: string;
  itemName?: string;
  brandName?: string;
  countryName?: string;
  barcode: string;
  fieldName: string;
  previousFieldValue: string;
  newFieldValue: string;
  dateCreate: Date;
  dateUpdated: Date;
}

export interface Lot extends BasicEntity {
  itemId: string;
  unitOfMeasureId: string;
  lotPrice: number;
  packagingQuantity: number;
  packagingLotInitialQuantity: number;
  packagingLotCurrentQuantity: number;
  lotInitialQuantity: number;
  lotCurrentQuantity: number;
  isForAuction?: boolean;
  isPublic?: boolean;
  productionDate: string;
  expirationDate: string;
  dateCreated: string;
  dateUpdated: string;
  auctionStartDate: string;
  auctionEndDate: string;
}

export interface Item extends BasicEntity {
  itemId: string;
  itemName: string;
  countrySourceId: string;
  countryName?: string;
  brandId: string;
  brandName?: string;
  customerItemId: string;
  itemDurationDays: number;
}

export interface Element extends BasicEntity {
  elementId: string;
  elementName: string;
  elementImg: string;
}

export interface ItemStock extends BasicEntity {
  itemId: string;
  itemName: string;
  unitOfMeasurePrice: number;
  unitOfMeasureQuantityAvg: number;
  totalQuantityOnCart?: string;
  totalUnitOfMeasureOnCart?: string;
  unitOfMeasurePriceOnCart?: string;
  stockQuantity: string;
  totalStockQuantity: string; // Includes the non-published stock
  totalItemQuantity?: string;
  brandName: string;
  countryName: string;
  packagingName: string;
  unitOfMeasureName: string;
  elaborarionDate: string;
  expirationDate: string;
}

/**
 * sku.item_id AS _id,
  stock_origin.stock_origin_name,
  item.item_name,
  brand.brand_name,
  country.country_name,
  available_and_published_stock.packaging_name,
  available_and_published_stock.unit_of_measure_name,
  COALESCE(reserved_stock.reserved_stock, 0) AS reserved_stock_quantity,
  COALESCE(available_and_published_stock.unpublished_stock, 0) AS unpublished_stock_quantity,
  COALESCE(available_and_published_stock.published_stock, 0) AS published_stock_quantity,
  COALESCE(available_and_published_stock.published_stock, 0) - COALESCE(reserved_stock.reserved_stock, 0) AS available_published_stock_quantity,
  COALESCE(available_and_published_stock.unpublished_stock, 0) + COALESCE(available_and_published_stock.published_stock, 0) - COALESCE(reserved_stock.reserved_stock, 0) AS available_stock_quantity,
  COALESCE(available_and_published_stock.published_unit_of_measure_quantity, 0) AS published_unit_of_measure_quantity,
  COALESCE(available_and_published_stock.available_unit_of_measure_quantity, 0) AS available_unit_of_measure_quantity,
  available_and_published_stock.elaboration_date,
  available_and_published_stock.expiration_date
 */
export interface AvailableStock extends BasicEntity {
  itemName: string;
  brandName: string;
  countryName: string;
  packagingName: string;
  unitOfMeasureName: string;
  totalStockQuantity: string;
  availablePublishedStockQuantity: string;
  totalAvailableStockQuantity: string;
  reservedStockQuantity: string;
  unpublishedStockQuantity: string;
  publishedStockQuantity: string;
  stockOriginName?: string;
  publishedAvailableUnitOfMeasureQuantity: string;
  availablePublishedUnitOfMeasureQuantity: string;
  elaborationDate: string;
  expirationDate: string;
}

export interface LotItem extends BasicEntity {
  itemId: string;
  itemName: string;
  brandName: string;
  countryName: string;
  packagingId: string;
  packagingName: string;
  packagingLotInitialQuantity: number;
  packagingLotCurrentQuantity: number;
  unitOfMeasureName: string;
  unitOfMeasureId: string;
  lotInitialQuantity: number;
  lotCurrentQuantity: number;
  totalQuantityOnCart?: string;
  isForAuction: boolean;
  totalUnitQuantityOnCart?: string;
  lotPrice: number;
  lotUnitOfMeasurePriceOnCart?: number;
  productionDate: string;
  expirationDate: string;
  dateCreated: string;
}

export interface Stock extends BasicEntity {
  preStockId: string;
  barcode: string;
  itemId: string;
  itemName: string;
  brandId: string;
  brandName: string;
  stateName: string;
  stockOriginId: string;
  stockOriginName?: string;
  countrySourceId: string;
  countryName: string;
  unitOfMeasureId: string;
  unitOfMeasureName: string;
  lotDetailStateId: string;
  packagingId: string;
  unitOfMeasureQuantity: string;
  elaborationDate: string;
  expirationDate: string;
  dateCreated: string;
  dateUpdated: string;
}

export interface Sku extends BasicEntity {
  skuId: string;
  itemId: string;
  stockOriginId: string;
  skuUnitOfMeasurePrice: number;
  modifiedByUserId: string;
  dateCreated: string;
  dateUpdated: string;
  itemName: string;
  brandName: string;
  countryName: string;
  stockOriginName: string;
  userUsername: string;
}

export interface PriceListEstandar extends BasicEntity {
  itemName: string;
}

export interface PriceListBolsa extends BasicEntity {
  itemName: string;
  brandName: string;
  stockQuantity: string;
  priceWithDiscount: number;
  elaborationDate: string;
  expirationDate: string;
}

export interface ShoppingCart extends BasicEntity {
  lotDetailId: string;
  lotId: string;
  itemId: string;
  itemName: string;
  brandName: string;
  countryName: string;
  stockOriginName?: string;
  unitOfMeasureId: string;
  lotDetailStateId: string;
  packagingId: string;
  unitOfMeasurePrice: number;
  unitOfMeasureQuantity: number;
  packagingName: string;
  lotPrice: string;
  userBuyerId: string;
  dateCreated: string;
  dateUpdated: string;
  elaborationDate: string;
  expirationDate: string;
  isForAuction: boolean;
  isDispatch: boolean;
  saleOrderId: string;
  priceDiscount: number;
  unitOfMeasureDeliveryCharge: number;
}

export interface SaleOrder extends BasicEntity {
  saleOrderId: string;
  userBuyerId: string;
  userRequesterId: string;
  userBuyerFullname?: string;
  userRequesterFullname?: string;
  userBuyerEmail?: string;
  subtotal: string;
  saleOrderDate: string;
  saleOrderExpirationDate: Date;
  saleOrderStateId: string;
  saleOrderStateName?: string;
  dateCreated: string;
  dateUpdated: string;
}

export interface SaleOrderDetail extends BasicEntity {
  saleOrderDetailId: string;
  saleOrderId: string;
  lotId: string;
  itemId: string;
  itemName: string;
  brandName: string;
  countryName: string;
  unitOfMeasureId: string;
  unitOfMeasureName: string;
  lotDetailStateId: string;
  packagingId: string;
  unitOfMeasurePrice: number;
  unitOfMeasureQuantityAvg: number;
  totalItemQuantity: number;
  itemQuantityInSaleOrder: number;
  appliedRuleIds: string[];
  packagingName: string;
  stockOriginName?: string;
  discountPerKg: number;
  deliveryChargePerKg: number;
  dateCreated: string;
  dateUpdated: string;
}

export interface Cart extends BasicEntity {
  cartId: string;
  cartStateId: string;
  userBuyerId: string;
  cartExpirationDate: string;
  cartTotalPrice: number;
  totalNetPrice: number;
  cartOriginalTotalPrice: number;
  cartTotalDiscount: number;
  cartTotalDeliveryCharge: number;
  taxPrice: number;
  isDispatch: boolean;
  deliveryAddress: string;
  stateName: string;
  packagingQuantity: string;
  unitOfMeasureQuantity: string;
  requesterUserFullname: string;
  buyerUserFullname: string;
  buyerUserEmail: string;
  dateCreated: string;
  dateUpdated: string;
  cartOrderDate: string;
  cartSaleDate: string;
  lotId?: string;
}

export interface CartDetail extends BasicEntity {
  cartDetailId: string;
  stockId: string;
  barcode: string;
  itemName: string;
  brandName: string;
  countryName: string;
  stockOriginName?: string;
  stateName: string;
  unitOfMeasureName: string;
  packagingName: string;
  unitOfMeasureQuantity: number;
  unitOfMeasurePrice: number;
  priceDiscount: number;
  unitOfMeasureDeliveryCharge: number;
  elaborationDate: string;
  expirationDate: string;
}

export interface User extends BasicEntity {
  userId: string;
  userUsername: string;
  userEmail: string;
  userPassword: string;
  userFullname: string;
  userTypeId: string;
  userTypeName: string;
  userIsActive: string;
  nationalId?: string;
  contactName?: string;
  phoneNumber?: string;
  businessNickname?: string;
  deliveryAddress?: string;
  lineOfBusiness?: string;
  dateCreated: string;
  dateUpdated: string;
}

export interface UserDeliveryAddress extends BasicEntity {
  userDeliveryAddressId: string;
  userId: string;
  userUsername: string;
  deliveryAddress: string;
  defaultDeliveryAddress: string;
  dateCreated: string;
  dateUpdated: string;
  districtName: string;
  cityName: string;
  regionName: string;
}

export interface BarcodeSearch extends BasicEntity {
  barcode: string;
  isLoted: boolean;
  lotId: string;
  cartId: string;
  itemName: string;
  brandName: string;
  countryName: string;
  unitOfMeasurePrice: number;
  unitOfMeasureQuantity: number;
  stateName: string;
  isForAuction: boolean;
  userFullname: string;
  dateUpdated: string;
  dateUpdatedLot: string;
}

export interface HistoricalPriceType extends BasicEntity {
  brandName: string;
  fecha: string;
  itemName: string;
  precio: string;
}

export interface BasicEntity {
  _id: string;
}
